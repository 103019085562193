<script>
import api from '@/command/api'
import TemplateHeaderTable from '@/components/TemplateHeaderTable'
import apiTool from '@/command/apiTool'
import { Drawer, Input, Select } from 'ant-design-vue'
import { getAction, postAction, putAction } from '@/command/netTool'
import DrawerFormModal from '@/components/DrawerFormModal'
export default {
  name: 'scenicSpotGuideStudyFurther',
  data() {
    return {
      ...api.command.getState(),
      language_type: [],
      dw: '',
      showModal: false,
      formDetail: {
        scenicGuideNameList: [],
      },
    }
  },
  mounted() {
    this.getList()
    getAction('/admin/dict/codes?codes=language_type', {}, '/bpi').then((e) => {
      this.language_type = (e.data['language_type'] || []).map((e) => ({
        name: e.name,
        value: e.id,
      }))
    })
  },
  methods: {
    getList() {
      api.command.getList.call(this, {
        url: '/farmScenicGuide/page',
        current: 1,
      })
    },
    getHeader() {
      return [
        {
          name: '名称',
          key: 'name',
          type: 'input',
        },
      ]
    },
    getTypeData() {
      return [
        {
          name: '景点',
          value: '001',
        },
        {
          name: '酒店',
          value: '002',
        },
        {
          name: '休闲娱乐',
          value: '003',
        },
        {
          name: '公共设施',
          value: '004',
        },
        {
          name: '研学',
          value: '005',
        },
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left',
          sorter: (a, b) => a.id - b.id,
        },
        {
          dataIndex: 'name',
          title: '名称',
          align: 'left',
          sorter: (a, b) => a.name.length - b.name.length,
        },
        {
          dataIndex: 'selectIcon',
          title: '选中图标',
          align: 'left',
          customRender: function (text, records) {
            return <img src={text} style={{ width: '30px', height: '30px' }} />
          },
        },
        {
          dataIndex: 'unselectIcon',
          title: '未选中图标',
          align: 'left',
          customRender: function (text, records) {
            return <img src={text} style={{ width: '30px', height: '30px' }} />
          },
        },
        {
          dataIndex: 'code',
          title: '类型',
          align: 'left',
          customRender: (text) => {
            const codeObj = {
              '001': '景点',
              '002': '酒店',
              '003': '休闲娱乐',
              '004': '公用设施',
              '005': '研学',
            }
            return codeObj[text]
          },
        },
        {
          dataIndex: 'upDown',
          title: '状态',
          type: 'badge',
          onExport: (records) => {
            return ['下架', '上架'][records]
          },
          filterMultiple: false,
          render(data) {
            return {
              showDot: true,
              name: data == 0 ? '上架' : '下架',
              color: data == 0 ? 'green' : 'red',
            }
          },
        },
        {
          dataIndex: 'a8',
          title: '操作',
          type: 'buttonGroup',
          width: '10%',
          typeData: ({ records }) => {
            return [
              {
                name: records.upDown == 0 ? '下架' : '上架',
                display: true,
                onClick: () => {
                  if (records.upDown == 0) {
                    api.command.editGET.call(this, {
                      url: `/farmScenicGuide/down?id=${records.id}`,
                    })
                  } else {
                    api.command.editGET.call(this, {
                      url: `/farmScenicGuide/up?id=${records.id}`,
                    })
                  }
                },
              },
              {
                name: '编辑',
                display: true,
                onClick: () => {
                  getAction(`/farmScenicGuide/detail?id=${records.id}`).then((e) => {
                    this.formDetail = e.data
                    this.showModal = true
                  })
                },
              },
              {
                name: '删除',
                display: true,
                type: 'pop',
                popTitle: '确认是否删除吗?',
                onClick: () => {
                  api.command.del.call(this, {
                    url: `/farmScenicGuide?id=${records.id}`,
                  })
                },
              },
              {
                name: '地址管理',
                display: true,
                onClick: () => {
                  switch (records.code) {
                    case '001':
                      this.$router.push(`/scenicSpotGuide/scenicSpot?id=${records.id}`)
                      return
                    case '002':
                      this.$router.push(`/scenicSpotGuide/groggery?id=${records.id}`)
                      return
                    case '003':
                      this.$router.push(`/scenicSpotGuide/shop?id=${records.id}`)
                      return
                    case '004':
                      this.$router.push(`/scenicSpotGuide/publicFacilities?id=${records.id}`)
                      return
                    case '005':
                      this.$router.push(`/scenicSpotGuide/studyFurther?id=${records.id}`)
                      return
                  }
                },
              },
            ].filter((e) => e.display)
          },
        },
      ]
    },

    openModal(form = {}) {
      apiTool.showModal({
        width: '800px',
        title: '中心点设置',
        formData: form,
        success: (data) => {
          const lng = data.data.lng || data.data.lon || form.lng
          postAction('/farmScenicGuide/centerPointConfig', { ...form, ...data.data, lon: lng }, '/api').then((e) => {
            this.$message.success('配置成功')
            data.setHidden()
          })
        },
        form: (data) => {
          return [
            {
              name: '中心点经纬度',
              type: 'latlng',
              cols: 24,
              key: 'dw',
              rules: [
                {
                  required: true,
                },
              ],
            },
            {
              type: 'input',
              name: '地图初始缩放级别',
              key: 'initialLevel',
              cols: 24,
            },
            {
              type: 'input',
              name: '最低缩放级别',
              key: 'lowestLevel',
              cols: 24,
            },
            {
              type: 'input',
              name: '最高缩放级别',
              key: 'highestLevel',
              cols: 24,
            },
            {
              type: 'input',
              name: 'VR地址',
              key: 'vrUrl',
              maxLength: 1000,
              cols: 24,
            },
            {
              type: 'radio',
              name: '类型选择',
              key: 'sliceType',
              typeData: [
                {
                  name: '切片类型',
                  value: '0',
                },
                {
                  name: '整图类型',
                  value: '1',
                },
              ],
              rules: [
                {
                  required: true,
                },
              ],
              cols: 24,
            },
            {
              type: 'input',
              name: '西南点位',
              key: 'transverseLonLat',
              cols: 24,
              display: data.sliceType == '1',
            },
            {
              type: 'input',
              name: '东北点位',
              key: 'longitudinalLonLat',
              cols: 24,
              display: data.sliceType == '1',
            },
            {
              type: 'input',
              name: '手绘地图链接',
              key: 'handDrawnMapUrl',
              cols: 24,
              maxLength: 1000,
            },
          ].filter((e) => (e.display == undefined ? true : e.display))
        },
      })
    },
    openCenterModel() {
      getAction('/farmScenicGuide/getCenterPointConfig').then(({ data }) => {
        if (data) {
          data.dw = data.lat + ',' + data.lon
          this.openModal(data)
        } else {
          this.openModal({ dw: '' })
        }
      })
    },
    getFormData() {
      return [
        {
          form: [
            {
              type: 'select',
              typeData: this.getTypeData(),
              name: '类型',
              placeholder: '请选择类型',
              key: 'code',
              rules: [
                {
                  required: true,
                  message: '请选择类型',
                },
              ],
            },
            {
              type: 'input',
              name: '名称中文',
              key: 'name',
              rules: [
                {
                  required: true,
                  message: '请输入名称',
                },
              ],
            },
            {
              type: 'row',
              children: [
                {
                  type: 'upload',
                  name: '选中图标',
                  key: 'selectIcon',
                  rules: [
                    {
                      required: true,
                      message: '请选择图标',
                    },
                  ],
                },
                {
                  type: 'upload',
                  name: '未选中图标',
                  key: 'unselectIcon',
                  rules: [
                    {
                      required: true,
                      message: '请选择图标',
                    },
                  ],
                },
              ],
            },
            {
              type: 'table',
              wrapperCol: 24,
              dataSource: this.formDetail.scenicGuideNameList,
              showPagination: false,
              tableType: 'editDelete',
              showFootButton: true,
              columns: [
                {
                  title: '语言类型',
                  align: 'center',
                  dataIndex: 'nameType',
                  customRender: (text, records, index) => {
                    return (
                      <Select
                        value={text}
                        onChange={(e) => {
                          this.formDetail.scenicGuideNameList[index]['nameType'] = e
                          this.$forceUpdate()
                        }}
                      >
                        {this.language_type.map((e) => {
                          return <Select.Option value={e.value}>{e.name}</Select.Option>
                        })}
                      </Select>
                    )
                  },
                },
                {
                  title: '内容',
                  align: 'center',
                  dataIndex: 'name',
                  customRender: (text, records) => {
                    return (
                      <Input
                        value={text}
                        onInput={(e) => {
                          records.name = e.target.value
                        }}
                      />
                    )
                  },
                },
                {
                  dataIndex: 'a8',
                  title: '操作',
                  type: 'buttonGroup',
                  typeData: ({ records, index }) => {
                    return [
                      {
                        name: '删除',
                        display: true,
                        type: 'pop',
                        popTitle: '确认是否删除吗?',
                        onClick: () => {
                          this.formDetail.scenicGuideNameList.splice(index, 1)
                        },
                      },
                    ]
                  },
                },
              ],
            },
          ],
        },
      ]
    },
    openInfoDrawer() {
      this.showModal = true
      this.formDetail = { scenicGuideNameList: [] }
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => this.openInfoDrawer(),
        },
        {
          name: '中心点设置',
          type: 'primary',
          icon: 'plus',
          onClick: () => this.openCenterModel(),
        },
      ]
    },
    onSubmit(data) {
      if (data.id) {
        putAction('/farmScenicGuide/update', data).then((e) => {
          this.$message.success('编辑成功')
          this.showModal = false
          this.detail = {}
          this.getList()
        })
      } else {
        postAction('/farmScenicGuide/save', data).then((e) => {
          this.$message.success('保存成功')
          this.showModal = false
          this.detail = {}
          this.getList()
        })
      }
    },
  },
  render() {
    console.log('DrawerFormModal', this.formDetail)
    return (
      <div>
        <TemplateHeaderTable
          headerData={this.getHeader()}
          tableColumns={this.getColumns()}
          button={this.getButton()}
          records={this.records}
        />
        <DrawerFormModal
          visible={this.showModal}
          title="基本信息"
          width={'800px'}
          onClose={() => (this.showModal = false)}
          onSubmit={this.onSubmit}
          data={this.getFormData()}
          form={this.formDetail}
        ></DrawerFormModal>
      </div>
    )
  },
}
</script>
